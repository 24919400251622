@font-face {
  font-family: 'Glacial Indifference';
  src: local('Glacial Indifference'), url('../assets/fonts/GlacialIndifference-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Glacial Indifference Bold';
  src: local('Glacial Indifference Bold'), url('../assets/fonts/GlacialIndifference-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Cheddar Gothic Sans';
  src: local('Cheddar Gothic Sans'), url('../assets/fonts/CheddarGothic-Sans.otf') format('opentype');
}

@font-face {
  font-family: 'League Spartan';
  src: local('League Spartan'), url('../assets/fonts/LeagueSpartan-Bold.otf') format('opentype');
}
